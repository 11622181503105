import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useAuth } from '../sign_up/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import defaultProPic from './default-propic2.jpeg';
import { FaCamera, FaEdit } from 'react-icons/fa';
import OttoLogo from './../company_images/images/logoottoackerman.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { API_URL } from '../../contsants';


const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  color: #172c47;
  animation: ${fadeIn} 0.5s ease-out;

  @media (max-width: 320px) {
    min-height: 60vh;
  }

`;

const Header = styled.header`
  padding: 1rem 2rem;
//   box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #172c47;
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  color: #172c47;
`;

const Nav = styled.nav`
  a {
    color: #172c47;
    text-decoration: none;
    margin-left: 1rem;
    transition: color 0.3s ease;

    &:hover {
      color: #bcbec0;
    }
  }
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  padding: 2rem;
  gap: 2rem;
`;

const ProfileSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfilePicture = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CameraIconWrapper = styled.label`
  position: absolute;
  bottom: 30px;
  right: 40px;
  background-color: #3498db;
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CameraIcon = styled(FaCamera)`
  position: absolute;
  bottom: 30px;
  right: 40px;
  background-color: #172c47;
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
`;

const ProfileName = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const ProfileEmail = styled.p`
  color: #172c47;
  margin-bottom: 1rem;
`;

const EditProfileButton = styled.button`
  background-color: #172c47;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const FormSection = styled.section`
  flex: 2;
  background-color: #f8f9f9;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  animation: ${slideIn} 0.5s ease-out;
  height: 400px;
`;

const Form = styled.form`
  display: grid;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e0e6ed;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #172c47;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  color: #8194a7;
  transition: all 0.3s ease;
  pointer-events: none;

  ${Input}:focus ~ &,
  ${Input}:not(:placeholder-shown) ~ & {
    top: -0.5rem;
    left: 0.5rem;
    font-size: 0.75rem;
    color: #172c47;
    background-color: white;
    padding: 0 0.25rem;
  }
`;

const SubmitButton = styled.button`
  background-color: #172c47;
  color: #efcf96;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #efcf96;
    color: #172c47;
  }
`;

const LogoImg= {
    position: 'absolute',
    left: '50%',
    transform:' translateX(-50%)',
    maxHeight: '100px',
    width: 'auto',
    display: 'block',
    transition: 'all 0.3s ease',
    cursor: 'pointer'
}
  

const UserProfile = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
      first_name: '',
      last_name: '',
      email: '',
    });

    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePicturePreview, setProfilePicturePreview] = useState('');
    const [message, setMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    

    useEffect(() => {
      const fetchUserData = async () => {
        const token = localStorage.getItem('accessToken'); 
        const id = localStorage.getItem('id'); 

        AOS.init({ duration: 1000, once: false });

        try {
          const response = await axios.get(`${API_URL}/users/user-profile/${id}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setFormData(response.data);
          if (response.data.image) {
            setProfilePicturePreview(response.data.image);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setMessage('Failed to load user data');
          setIsError(true);
        }
      };
  
      fetchUserData();
    }, []);
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          setProfilePicture(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setProfilePicturePreview(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
  
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        const formDataToSend = new FormData();
        formDataToSend.append('first_name', formData.first_name);
        formDataToSend.append('last_name', formData.last_name);
        
        if (profilePicture) {
          formDataToSend.append('image', profilePicture);
        }
      
        try {
          const token = localStorage.getItem('accessToken');
          const id = localStorage.getItem('id');

          await axios.patch(`${API_URL}/users/user-profile/${id}/`, formDataToSend, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          setMessage('Profile updated successfully');
          setIsError(false);
        } catch (error) {
          console.error('Error updating profile:', error);
          setMessage('Failed to update profile');
          setIsError(true);
        }
      };
  
    return (
      <ProfileContainer>
        <Header>
          <Logo >Otto Profile</Logo>
          <img src={OttoLogo} alt="logo" style={LogoImg}  onClick={() => navigate('/')} />
          <Nav style={{color: '#172c47'}}>
            <a href="/">Home</a>
            <a href="#">Orders</a>
            <a onClick={logout}>Sign Out</a>
          </Nav>
        </Header>
        <MainContent>

          <ProfileSection>
              <div data-aos="fade-up"
                   data-aos-anchor-placement="top-bottom"
                   data-aos-delay="200">
                <ProfilePicture>
                    <img src={profilePicturePreview || defaultProPic} alt="Profile" />
                    <CameraIconWrapper htmlFor="profilePicInput">
                    <FaCamera />
                    </CameraIconWrapper>
                    <input
                    id="profilePicInput"
                    type="file"
                    accept="image/*"
                    onChange={handleProfilePictureChange}
                    style={{ display: 'none' }}
                    />
                </ProfilePicture>
                </div>
                <div data-aos="fade-up"
                   data-aos-anchor-placement="top-bottom"
                   data-aos-delay="300">
                <ProfileName>{formData.first_name} {formData.last_name}</ProfileName>
                </div>
                <div data-aos="fade-up"
                   data-aos-anchor-placement="top-bottom"
                   data-aos-delay="400">
                <ProfileEmail>{formData.email}</ProfileEmail>
          </div>
         </ProfileSection>

          <FormSection>
            <h2>Profile Information</h2>
            <Form onSubmit={handleSubmit}>
              <InputGroup>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder=" "
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                />
                <Label htmlFor="first_name">First Name</Label>
              </InputGroup>
              <InputGroup>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder=" "
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                />
                <Label htmlFor="last_name">Last Name</Label>
              </InputGroup>
              <InputGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder=" "
                  value={formData.email}
                  onChange={handleChange}
                  required
                  readOnly
                  style={{ backgroundColor: '#f0f0f0', cursor: 'not-allowed' }}
                />
                <Label htmlFor="email">Email</Label>
              </InputGroup>
              <SubmitButton type="submit">Update Profile</SubmitButton>
            </Form>
            {message && <p style={{ color: isError ? 'red' : 'green' }}>{message}</p>}
          </FormSection>
        </MainContent>
      </ProfileContainer>
    );
  };
  

export default UserProfile;